import { useDatabaseListQuery } from "metabase/common/hooks";

export function useShouldShowDatabasePromptBanner(): boolean | undefined {
  const isEligibleForDatabasePromptBanner = false;

  const { data: databases } = useDatabaseListQuery({
    enabled: isEligibleForDatabasePromptBanner,
  });

  if (!isEligibleForDatabasePromptBanner) {
    return false;
  }

  if (databases === undefined) {
    return undefined;
  }

  return databases.every(database => database.is_sample);
}
