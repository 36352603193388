import { Component } from "react";
import PropTypes from "prop-types";

import { PLUGIN_LOGO_ICON_COMPONENTS } from "metabase/plugins";

class DefaultLogoIcon extends Component {
  static defaultProps = {
    height: 32,
  };
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    dark: PropTypes.bool,
  };

  render() {
    const { height, width } = this.props;
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 453 116"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3_81)">
          <path
            d="M98.7009 0H17.5056C7.83753 0 0 7.8236 0 17.4745V98.5255C0 108.176 7.83753 116 17.5056 116H98.7009C108.369 116 116.206 108.176 116.206 98.5255V17.4745C116.206 7.8236 108.369 0 98.7009 0Z"
            fill="#0040F2"
          ></path>
          <path
            d="M70.7852 47.1866L32.1082 24.037C28.2065 21.7034 23.2402 24.5081 23.2402 29.0493V75.343C23.2402 79.8842 28.2065 82.6944 32.1082 80.3553L70.7852 57.2112C74.5772 54.9433 74.5772 49.4545 70.7852 47.1811V47.1866Z"
            fill="white"
          ></path>
          <path
            d="M65.2207 80.4156L84.2738 91.8206C88.1756 94.1541 93.1419 91.3495 93.1419 86.8083V64.0038C93.1419 59.4626 88.1756 56.6524 84.2738 58.9915L65.2207 70.3965C61.4287 72.6643 61.4287 78.1532 65.2207 80.4265V80.4156Z"
            fill="#B6DBFF"
          ></path>
          <path
            d="M152.48 35.6556L139.353 12.0514H146.076L156.173 30.7858L152.48 35.6556ZM142.553 47.0661L140.385 41.9991C142.289 41.5389 144.007 40.8597 145.543 39.9558C147.08 39.052 148.479 37.8578 149.742 36.3678C151.004 34.8778 152.173 33.0317 153.237 30.8351L162.423 12.0514H168.8L158.379 32.5552C157.232 34.8175 156.074 36.7786 154.905 38.4439C153.742 40.1092 152.524 41.5115 151.262 42.6619C149.999 43.8068 148.644 44.738 147.212 45.4392C145.774 46.1458 144.221 46.6827 142.542 47.0606L142.553 47.0661Z"
            fill="black"
          ></path>
          <path
            d="M172.768 46.4745V12.0514H200.502V46.4745H194.987V17.091H178.288V46.469H172.773L172.768 46.4745Z"
            fill="black"
          ></path>
          <path
            d="M207.845 46.4745V12.0514H220.329C222.777 12.0514 224.939 12.4732 226.821 13.3168C228.703 14.1604 230.174 15.3765 231.244 16.9541C232.314 18.5372 232.846 20.4326 232.846 22.6456C232.846 24.8587 232.353 26.8472 231.37 28.4084C230.388 29.9751 228.95 31.1638 227.073 31.9745C225.191 32.7852 222.908 33.1906 220.214 33.1906H213.365V46.469H207.85L207.845 46.4745ZM213.36 28.4796H220.28C222.513 28.4796 224.247 28.0085 225.477 27.0663C226.706 26.1241 227.326 24.6944 227.326 22.7771C227.326 20.8598 226.695 19.4192 225.427 18.4003C224.165 17.3869 222.464 16.8774 220.329 16.8774H213.36V28.4796Z"
            fill="black"
          ></path>
          <path
            d="M230.865 46.4745L244.266 12.0514H250.253L263.994 46.4745H257.76L254.583 38.2631H239.975L236.918 46.4745H230.86H230.865ZM241.407 33.5193H253.107L247.147 18.5208L241.407 33.5193Z"
            fill="black"
          ></path>
          <path
            d="M268.439 46.4745V12.0514H280.287C283.585 12.0514 286.258 12.7854 288.294 14.2535C290.33 15.7216 291.35 17.8799 291.35 20.7339C291.35 21.8349 291.092 22.8702 290.577 23.8453C290.061 24.8204 289.391 25.6749 288.568 26.4144C287.745 27.154 286.894 27.6908 286.005 28.0359C287.877 28.6111 289.441 29.6573 290.697 31.1802C291.954 32.7031 292.58 34.582 292.58 36.8115C292.58 38.6959 292.102 40.3666 291.153 41.8128C290.198 43.2645 288.848 44.4039 287.086 45.231C285.33 46.0582 283.245 46.4745 280.83 46.4745H268.439ZM273.954 26.7541H279.42C280.748 26.7541 281.901 26.5404 282.866 26.1131C283.838 25.6859 284.59 25.0723 285.133 24.2671C285.676 23.4618 285.945 22.4977 285.945 21.3638C285.945 19.7588 285.374 18.5263 284.233 17.6662C283.091 16.8062 281.462 16.3734 279.343 16.3734H273.949V26.7486L273.954 26.7541ZM273.954 42.147H279.892C282.257 42.147 284.013 41.6868 285.16 40.7556C286.307 39.8298 286.883 38.4329 286.883 36.565C286.883 34.697 286.307 33.3056 285.149 32.336C283.991 31.3665 282.208 30.8844 279.793 30.8844H273.954V42.147Z"
            fill="black"
          ></path>
          <path
            d="M298.572 47.0606L296.503 41.9936C297.601 41.6485 298.55 41.2322 299.335 40.7391C300.125 40.2461 300.784 39.5778 301.316 38.7342C301.848 37.8906 302.271 36.7841 302.573 35.4146C302.874 34.0451 303.094 32.3141 303.226 30.2271C303.357 28.14 303.423 25.5818 303.423 22.5689V12.0514H327.536V46.4745H322.021V17.091H308.719V22.3772C308.719 25.9488 308.609 29.0274 308.4 31.6075C308.186 34.1876 307.841 36.3678 307.352 38.1481C306.869 39.9284 306.233 41.391 305.454 42.5468C304.674 43.7027 303.714 44.6339 302.573 45.3351C301.431 46.0417 300.098 46.6114 298.572 47.0552V47.0606Z"
            fill="black"
          ></path>
          <path
            d="M334.308 46.4745V12.0514H356.598V16.9212H339.828V26.2172H354.31V30.8132H339.828V41.5553H356.747V46.4745H334.308Z"
            fill="black"
          ></path>
          <path
            d="M362.278 46.4745V12.0514H367.793V25.7187H384.492V12.0514H390.007V46.4745H384.492V30.7858H367.793V46.4745H362.278Z"
            fill="black"
          ></path>
          <path
            d="M396.806 46.4745V12.0514H402.3V37.4743L419.542 12.0514H424.865V46.4745H419.399V20.8818L402.086 46.4745H396.817H396.806Z"
            fill="black"
          ></path>
          <path
            d="M430.561 46.4745V12.0514H452.852V16.9212H436.082V26.2172H450.563V30.8132H436.082V41.5553H453V46.4745H430.561Z"
            fill="black"
          ></path>
          <path
            d="M139.869 104.48L153.27 70.0569H159.257L172.998 104.48H166.764L163.587 96.2686H148.979L145.922 104.48H139.864H139.869ZM150.411 91.5248H162.111L156.151 76.5263L150.411 91.5248Z"
            fill="black"
          ></path>
          <path
            d="M176.9 104.48V70.0569H182.415V83.7242H188.819V88.7913H182.415V104.48H176.9ZM198.252 104.48L184.138 86.3098L196.672 70.0569H203.471L190.86 86.2824L205.37 104.475H198.252V104.48Z"
            fill="black"
          ></path>
          <path
            d="M216.345 104.48V75.102H206.643V70.0623H231.568V75.102H221.866V104.48H216.351H216.345Z"
            fill="black"
          ></path>
          <path
            d="M236.831 104.48V70.0569H242.324V95.4798L259.566 70.0569H264.889V104.48H259.423V78.8872L242.11 104.48H236.841H236.831Z"
            fill="black"
          ></path>
          <path
            d="M270.585 104.48V70.0569H282.433C285.731 70.0569 288.403 70.7909 290.439 72.259C292.475 73.7271 293.496 75.8854 293.496 78.7393C293.496 79.8404 293.238 80.8757 292.722 81.8508C292.206 82.8258 291.537 83.6804 290.714 84.4199C289.891 85.1594 289.04 85.6963 288.151 86.0414C290.022 86.6166 291.586 87.6628 292.843 89.1857C294.1 90.7085 294.725 92.5875 294.725 94.817C294.725 96.7014 294.248 98.3721 293.298 99.8183C292.344 101.27 290.994 102.409 289.232 103.237C287.476 104.064 285.391 104.48 282.976 104.48H270.585ZM276.1 84.7595H281.566C282.894 84.7595 284.046 84.5459 285.012 84.1186C285.983 83.6914 286.735 83.0778 287.278 82.2726C287.822 81.4673 288.091 80.5032 288.091 79.3693C288.091 77.7643 287.52 76.5317 286.378 75.6717C285.237 74.8117 283.607 74.3789 281.489 74.3789H276.095V84.7541L276.1 84.7595ZM276.1 100.152H282.038C284.403 100.152 286.159 99.6923 287.306 98.7611C288.453 97.8353 289.029 96.4384 289.029 94.5705C289.029 92.7025 288.453 91.3111 287.295 90.3415C286.137 89.3719 284.354 88.8899 281.939 88.8899H276.1V100.152Z"
            fill="black"
          ></path>
          <path
            d="M295.653 104.48L309.053 70.0569H315.04L328.782 104.48H322.548L319.37 96.2686H304.762L301.705 104.48H295.647H295.653ZM306.194 91.5248H317.894L311.934 76.5263L306.194 91.5248Z"
            fill="black"
          ></path>
          <path
            d="M332.134 104.48V70.0569H337.479L351.544 92.8997L349.399 92.9216L362.948 70.0569H368.194V104.48H362.799L362.871 77.3863L363.93 77.7533L352.208 97.052H348.169L336.223 77.7533L337.112 77.3863L337.183 104.48H332.134Z"
            fill="black"
          ></path>
          <path
            d="M373.863 104.48V70.0569H379.356V95.4798L396.598 70.0569H401.921V104.48H396.455V78.8872L379.142 104.48H373.874H373.863Z"
            fill="black"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_3_81">
            <rect width="453" height="116" fill="white"></rect>
          </clipPath>
        </defs>
      </svg>
    );
  }
}

export default function LogoIcon(props) {
  const [Component = DefaultLogoIcon] = PLUGIN_LOGO_ICON_COMPONENTS;
  return <Component {...props} />;
}
